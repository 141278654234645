import { axiosInstance } from ".";

export const fileService = (state) => {
  return {
    async upload(file) {
      const response = await axiosInstance.post("uploader", file, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response?.data || [];
    },
  };
};
