import i18n from "../i18n/i18n";
import { authService } from "./authService";
import axios from "axios";
import { config } from "../config";
import { fileService } from "./fileService";
import { cardTransactionService } from "./cardTransactionService";
import authStore from "../store/authStore";
import { slideSettingsService } from "./slideSettingsService";

export const axiosInstance = axios.create({
  baseURL: config.API_URL,
});

axiosInstance.interceptors.request.use((config) => {
  const store = JSON.parse(localStorage.getItem("AuthStore"));
  const lang = i18n.language;

  if (store.access_token) {
    config.headers["Authorization"] = `${store["access_token"]}`;
    config.headers["Accept-Language"] = lang;
  }
  return config;
});
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Unauthorized error, redirect to login
      authStore.logout();
      window.location.href = "/auth";
      console.log("UNAUTH", error.response);
    }
    return Promise.reject(error);
  }
);
export default {
  state: {
    config: {},
    baseUrl: "/api/v1",
  },
  async login(data) {
    return await authService(this.state).login(data);
  },
  async me(token) {
    return await authService(this.state).me(token);
  },
  async fileUpload(file) {
    return await fileService(this.state).upload(file);
  },
  async cards(type) {
    return await cardTransactionService(this.state).list(type);
  },
  async createCard(data) {
    return await cardTransactionService(this.state).create(data);
  },
  async updateCard(id, data) {
    return await cardTransactionService(this.state).update(id, data);
  },
  async deleteCard(id) {
    return await cardTransactionService(this.state).delete(id);
  },
  async getCardById(id) {
    return await cardTransactionService(this.state).get(id);
  },
  async slides(type) {
    return await slideSettingsService(this.state).list(type);
  },
  async updateSlides(data) {
    return await slideSettingsService(this.state).update(data);
  },
};
