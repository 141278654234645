import { Image } from "@mantine/core";
import { IconPencil, IconTrash } from "@tabler/icons-react";
import React from "react";

const CustomCard = ({ src, handleDelete, handleEdit }) => {
  return (
    <div className="relative flex-shrink-0 bg-yellow-700 p-2 overflow-hidden rounded-md">
      <div className="absolute top-0 right-0 flex gap-2">
        {handleEdit && (
          <button onClick={handleEdit}>
            <IconPencil className="text-blue-500" width={25} height={25} />
          </button>
        )}
        {handleDelete && (
          <button onClick={handleDelete}>
            <IconTrash className="text-red-500" width={25} height={25} />
          </button>
        )}
      </div>
      <Image
        radius="md"
        className="w-100 h-40 rounded-md"
        src={src}
        fit="contain"
        alt="Custom card image"
      />
    </div>
  );
};

export default CustomCard;
