import { axiosInstance } from ".";

export const cardTransactionService = (state) => {
  return {
    async list(type) {
      const response = await axiosInstance.get(!type ? `item` : `item?type=${type}`);
      return response?.data || [];
    },
    async get(id) {
      const response = await axiosInstance.get(`item/${id}`);
      return response?.data || [];
    },
    async create(data) {
      const response = await axiosInstance.post("item", data);
      return response?.data || [];
    },
    async update(id, data) {
      const response = await axiosInstance.put(`item/${id}`, data);
      return response?.data || [];
    },
    async delete(id) {
      const response = await axiosInstance.delete(`item/${id}`);
      return response?.data || [];
    },
  };
};
