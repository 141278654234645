import { axiosInstance } from ".";

export const slideSettingsService = (state) => {
  return {
    async list(type) {
      const response = await axiosInstance.get(`/slider/${type}`);
      return response?.data || [];
    },
    async update(data) {
      const response = await axiosInstance.post(`/slider`, data);
      return response?.data || [];
    },
  };
};
