import App from "../App";
import { Login } from "../pages/Auth";
import Auth from "../pages/Auth/Auth";
import { HomeIcon } from "@heroicons/react/24/outline";
import {
  IconSlideshow,
  IconShoppingBagDiscount,
  IconCards,
  IconColumns,
} from "@tabler/icons-react";
import NotFound from "../pages/Error/404";
import Slides from "../pages/Slides";
import SlidesList from "../pages/Slides/SlidesList";
import Sponsors from "../pages/Sponsors";
import SponsorList from "../pages/Sponsors/SponsorList";
import Column from "../pages/Column";
import ColumnList from "../pages/Column/ColumnList";
import Cards from "../pages/Cards";
import CardCreate from "../pages/Cards/CardCreate";
import CardList from "../pages/Cards/CardList";

const routes = [
  {
    name: "auth",
    path: "/auth",
    element: Auth,
    isPrivate: false,
    isVisible: false,
    role: "public",
    subRoutes: [
      {
        name: "Login",
        path: "login",
        element: Login,
        role: "public",
      },
      {
        name: "",
        path: "",
        to: "/auth/login",
        isVisible: false,
        isNavigate: true,
        role: "public",
      },
    ],
  },
  {
    name: "Dashboard",
    path: "/",
    icon: HomeIcon,
    isPrivate: true,
    role: "public",
    element: App,
  },
  {
    name: "Cards",
    path: "/cards",
    isPrivate: true,
    icon: IconCards,
    element: Cards,
    subRoutes: [
      {
        name: "Create",
        path: "create",
        element: CardCreate,
        isVisible: false,
      },
      {
        name: "Edit",
        path: "edit/:id",
        element: CardCreate,
        isVisible: false,
      },
      {
        name: "List",
        path: "list",
        element: CardList,
        isVisible: false,
      },
      {
        name: "",
        path: "",
        to: "/cards/list",
        isVisible: false,
        isNavigate: true,
      },
    ],
  },
  {
    name: "Slides",
    path: "/slides",
    isPrivate: true,
    icon: IconSlideshow,
    element: Slides,
    subRoutes: [
      {
        name: "List",
        path: "list",
        element: SlidesList,
        isVisible: false,
      },
      {
        name: "",
        path: "",
        to: "/slides/list",
        isVisible: false,
        isNavigate: true,
      },
    ],
  },
  {
    name: "Sponsors",
    path: "/sponsors",
    isPrivate: true,
    icon: IconShoppingBagDiscount,
    element: Sponsors,
    subRoutes: [
      {
        name: "List",
        path: "list",
        element: SponsorList,
        isVisible: false,
      },
      {
        name: "",
        path: "",
        to: "/sponsors/list",
        isVisible: false,
        isNavigate: true,
      },
    ],
  },
  {
    name: "Column",
    path: "/column",
    isPrivate: true,
    icon: IconColumns,
    element: Column,
    subRoutes: [
      {
        name: "List",
        path: "list",
        element: ColumnList,
        isVisible: false,
      },
      {
        name: "",
        path: "",
        to: "/column/list",
        isVisible: false,
        isNavigate: true,
      },
    ],
  },
  {
    path: "*",
    element: NotFound,
    name: "404",
    isVisible: false,
  },
];

export default routes;
