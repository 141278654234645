import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import services from "../../services";
import { useForm, useWatch } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import BarLoading from "../../components/Loading/BarLoading";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import toast from "react-hot-toast";
import validations from "./validations/cardCreate";
import { yupResolver } from "@hookform/resolvers/yup";

import SaveButton from "../../components/Buttons/SaveButton";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { Button, Group, Image, Text, rem } from "@mantine/core";
import { IconPhoto, IconUpload, IconX } from "@tabler/icons-react";
import { config } from "../../config";

const CardCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { id } = useParams();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    control,
    formState: { errors, defaultValues },
  } = useForm({
    resolver: yupResolver(validations),
  });

  const addCard = useMutation({
    mutationFn: (newCard) => services.createCard(newCard),
  });
  const updateCard = useMutation({
    mutationFn: (newCard) => services.updateCard(id, newCard),
  });

  const onSubmit = async (data) => {
    let newCardPayload;
    let card;
    const formData = new FormData();

    try {
      newCardPayload = {
        ...data,
      };

      if (data.image instanceof File && Object.values(errors).length === 0) {
        formData.append("file", data.image);
        const image = await services.fileUpload(formData);
        delete newCardPayload.image;
        newCardPayload = {
          ...newCardPayload,
          image: image?.key,
        };
      }

      if (!id) {
        card = await addCard.mutateAsync(newCardPayload);
      } else {
        card = await updateCard.mutateAsync(newCardPayload);
      }

      if (!id && card) {
        toast.success("Kart Oluşturuldu", {
          duration: 3000,
        });

        handleCancel();
      } else if (id && card) {
        toast.success(
          card?.data?.message ? card?.data?.message : "Kart Güncellenemiyor",
          {
            duration: 3000,
          },
        );
        handleCancel();
      }
    } catch (e) {
      toast.error(e.response.data.message || t("notifications.error.message"), {
        duration: 3000,
      });
    }
  };
  const type = useWatch({
    control,
    name: "type",
    defaultValue: null,
  });
  const image = useWatch({
    control,
    name: "image",
    defaultValue: "",
  });
  const handleCancel = () => {
    reset();
    navigate("/cards/list");
  };
  console.log("ERRORSS", errors);
  const fetchCard = useCallback(async () => {
    if (id) {
      setLoading(true);
      try {
        const company = await services.getCardById(id);
        Object.keys(company.data).forEach((key) => {
          setValue(key, company.data[key]);
        });
      } catch (e) {
        navigate("/cards");
        toast.error("Kartlar bulunamadı", {
          duration: 3000,
        });
      } finally {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset();

    if (id) {
      fetchCard();
    }
  }, [id]);
  useEffect(() => {
    console.log("🚀 ~ file: CardCreate.js:32 ~ CardCreate ~ errors:", errors);
  }, [errors]);
  return (
    <>
      {!loading ? (
        <div className="px-4 sm:px-6 lg:px-8">
          <form
            onSubmit={handleSubmit((data) =>
              onSubmit(data, getValues("image")),
            )}
          >
            <div className="space-y-12">
              <div className="border-b border-white/10 pb-12">
                <h2 className="text-base font-semibold leading-7 dark:text-white text-slate-700">
                  {id ? "Kartı Düzenle" : "Kart Oluştur"}
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-400">
                  {id ? "Kartı Düzenleyin" : "Kart Oluşturun"}
                </p>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 max-w-full">
                  <div className="sm:col-span-full">
                    <label
                      htmlFor="type"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {"Kart Tipi"}
                    </label>
                    <div className="mt-2">
                      <CustomSelect
                        control={control}
                        name="type"
                        placeholder={t("placeholders.select")}
                        value={type}
                        data={[
                          { value: "MAIN_SLIDER", label: "Ana slayt" },
                          { value: "COLUMN_CARD", label: "Sütun" },
                          { value: "SPONSOR_CARD", label: "Sponsor Kartı" },
                        ]}
                      />
                      <p className="mt-2 text-sm text-red-600" id="type-error">
                        {t(errors?.type?.message)}
                      </p>
                    </div>
                  </div>
                  <div className="sm:col-span-full">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      Kart İsmi
                    </label>
                    <div className="mt-2">
                      <input
                        {...register("name")}
                        type="text"
                        placeholder={"Kart ismi giriniz"}
                        className={`block w-full rounded-md border-0 dark:bg-gray-800 bg-gray-300/70 py-1.5 dark:text-white text-slate-700 shadow-sm ring-1 ring-inset dark:ring-white/10 ring-gray-400/20 focus:ring-2 focus:ring-inset focus:ring-yellow-500 sm:text-sm sm:leading-6
                        ${
                          errors?.name?.message &&
                          "!ring-red-500 dark:!ring-red-500"
                        }
                        `}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors?.name?.message)}
                      </p>
                    </div>
                  </div>
                  <div className="sm:col-span-full">
                    <label
                      htmlFor="href"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      Sponsor Websitesi Linki
                    </label>
                    <div className="mt-2">
                      <input
                        {...register("href")}
                        type="text"
                        placeholder={"Sponsor websitesi linki giriniz"}
                        className={`block w-full rounded-md border-0 dark:bg-gray-800 bg-gray-300/70 py-1.5 dark:text-white text-slate-700 shadow-sm ring-1 ring-inset dark:ring-white/10 ring-gray-400/20 focus:ring-2 focus:ring-inset focus:ring-yellow-500 sm:text-sm sm:leading-6
                        ${
                          errors?.href?.message &&
                          "!ring-red-500 dark:!ring-red-500"
                        }
                        `}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors?.href?.message)}
                      </p>
                    </div>
                  </div>
                  <div className="sm:col-span-full">
                    <label
                      htmlFor="sponsor-photo"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      Kart Fotoğrafı
                    </label>
                    <Dropzone
                      onDrop={(files) => {
                        setValue("image", files[0]);
                      }}
                      onReject={(files) => console.log("rejected files", files)}
                      maxSize={3 * 1024 ** 2}
                      multiple={false}
                      classNames={{
                        root: `dark:!bg-gray-800 !bg-gray-300/70 dark:!text-white !text-slate-700 mt-2`,
                      }}
                      accept={IMAGE_MIME_TYPE}
                    >
                      <Group
                        justify="center"
                        gap="xl"
                        mih={100}
                        style={{ pointerEvents: "none" }}
                      >
                        <Dropzone.Accept>
                          <IconPhoto
                            style={{
                              width: rem(52),
                              height: rem(52),
                              color: "var(--mantine-color-green-6)",
                            }}
                            stroke={1.5}
                          />
                        </Dropzone.Accept>
                        <Dropzone.Reject>
                          <IconX
                            style={{
                              width: rem(52),
                              height: rem(52),
                              color: "var(--mantine-color-red-6)",
                            }}
                            stroke={1.5}
                          />
                        </Dropzone.Reject>
                        <Dropzone.Idle>
                          {image ? (
                            <div className="w-full h-full flex justify-center items-center">
                              <Image
                                radius="md"
                                h={"auto"}
                                className="max-h-[200px] w-full"
                                w="100%"
                                fit="cover"
                                src={
                                  image instanceof File
                                    ? URL.createObjectURL(image)
                                    : `${config.CDN_URL}${image}`
                                }
                              />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center">
                              <IconUpload
                                style={{
                                  width: rem(52),
                                  height: rem(52),
                                  color: "var(--mantine-color-gray-6)",
                                }}
                                stroke={1.5}
                              />
                              <Text
                                className="text-white"
                                style={{ fontSize: rem(12) }}
                              >
                                {t("placeholders.upload")}
                              </Text>
                            </div>
                          )}
                        </Dropzone.Idle>
                      </Group>
                    </Dropzone>
                    <p className="mt-2 text-sm text-red-600" id="type-error">
                      {image ? null : "Fotoğraf Yüklemek Zorunludur*"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                onClick={handleCancel}
                className="text-sm font-semibold leading-6 dark:text-white text-slate-700"
              >
                {t("actions.cancel")}
              </button>
              <SaveButton
                disabled={id ? updateCard.isLoading : addCard.isLoading}
              />
            </div>
          </form>
        </div>
      ) : (
        <BarLoading />
      )}
    </>
  );
};

export default CardCreate;
