import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Badge, Image, Pagination, Select } from "@mantine/core";
import services from "../../services";
import BarLoading from "../../components/Loading/BarLoading";
import CustomSearch from "../../components/CustomSearch/CustomSearch";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import TableSkeleton from "../../components/Skeleton/TableSkeleton";
import { Carousel } from "@mantine/carousel";
import { config } from "../../config";
import CustomCard from "../../components/Cards/CustomCard";

const CardList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [id, setId] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const {
    isLoading: isCardListLoading,
    error: cardListError,
    data: cardListData,
    refetch: cardListDataRefetch,
  } = useQuery({
    queryKey: ["cardList"],
    queryFn: async () => await services.cards().then((res) => res.data),
  });

  if (cardListError) return <p>Error :(</p>;

  const handleNavigate = (page, id) => {
    switch (page) {
      case "create":
        navigate("/cards/create");
        break;
      case "edit":
        navigate("/cards/edit/" + id);
        break;
      case "delete":
        setId(id);
        setDeleteModalVisible(true);

        break;
      default:
        navigate("/cards/list");
    }
  };

  const handleDelete = async (id) => {
    await services.deleteCard(id).then((res) => {
      toast.success(res?.message ? res.message : "Başarıyla Silindi");
      setDeleteModalVisible(false);
      cardListDataRefetch();
    });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmModal
        isOpen={deleteModalVisible}
        onConfirm={() => handleDelete(id)}
        onClose={() => {
          setDeleteModalVisible(false);
          setId(null);
        }}
        title={"Kartı silmek istediğinize emin misiniz"}
        confirmText={"Kartı Sil"}
      />
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 dark:text-white text-slate-700">
            Kartlar
          </h1>
          <p className="mt-2 text-sm dark:text-gray-400 text-gray-500">
            Sayfanızda göstermek istediğiniz tüm kartların bir listesi
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex flex-col sm:flex-row xs:flex-col-reverse gap-2 xs:justify-between justify-start items-center">
          <button
            type="button"
            onClick={() => handleNavigate("create")}
            className="block xs:w-full rounded-md bg-yellow-600 dark:bg-yellow-800 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
          >
            Kart Ekle
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="dark:bg-gray-800 bg-gray-300 text-slate-700">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-gray-300 text-slate-700 sm:pl-6"
                    >
                      Kart Resmi
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-gray-300 text-slate-700 sm:pl-6"
                    >
                      Kart İsmi
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold dark:text-gray-300 text-slate-700"
                    >
                      Kart Tipi
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold dark:text-gray-300 text-slate-700"
                    >
                      Sponsor Linki
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold dark:text-gray-300 text-slate-700"
                    ></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:bg-gray-800 bg-gray-200 text-slate-700">
                  {isCardListLoading ? (
                    <TableSkeleton colSize={5} />
                  ) : (
                    cardListData.map((card, index) => (
                      <tr
                        key={card._id}
                        className={`${
                          index % 2 === 0
                            ? "dark:bg-slate-700/80 bg-gray-200"
                            : "dark:bg-slate-600/80 bg-gray-100"
                        } hover:bg-yellow-200/90 dark:hover:bg-amber-600/10`}
                      >
                        <td className="whitespace-nowrap py-4 pl-4 sm:pl-6">
                          <Image
                            radius="xs"
                            w={100}
                            h={100}
                            src={config.CDN_URL + card.image}
                            fit="contain"
                            alt="Custom card image"
                          />
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {card.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {card.type === "COLUMN_CARD"
                            ? "Sütun Kartı"
                            : card.type === "MAIN_SLIDER"
                            ? "Ana Slayt Kartı"
                            : "Sponsor Kartı"}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <a className="text-yellow-500" target="_blank" href={`${card.href}`}>
                            {card.href}
                          </a>
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium w-[150px] sm:pr-6 ">
                          <button
                            onClick={() => handleNavigate("edit", card._id)}
                            className="text-yellow-600 hover:text-yellow-900"
                          >
                            {t("actions.edit")}
                            <span className="sr-only">, {card.name}</span>
                          </button>

                          <button
                            onClick={() => handleNavigate("delete", card._id)}
                            className="text-yellow-600 hover:text-yellow-900 ml-3"
                          >
                            {t("actions.delete")}
                            <span className="sr-only">, {card.name}</span>
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardList;
