import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import services from "../../services";
import { useState } from "react";
import toast from "react-hot-toast";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import ColumnCreateModal from "./ColumnCreateModal";
import { IconPlus } from "@tabler/icons-react";
import { useListState } from "@mantine/hooks";
import CustomCard from "../../components/Cards/CustomCard";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { useEffect } from "react";
import cx from "clsx";
import classes from "../Slides/dndList.module.css";
import { config } from "../../config";
const ColumnList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [id, setId] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [addColumModalVisible, setAddColumModalVisible] = useState(false);
  const {
    isLoading: isColumnCardLoading,
    data: columnData,
    refetch: columnDataRefetch,
  } = useQuery({
    queryKey: ["item", "COLUMN_CARD"],
    queryFn: async () =>
      await services.slides("COLUMN_CARD").then((res) => res.data),
  });
  const [state, handlers] = useListState(columnData?.items);
  useEffect(() => {
    handlers.setState(columnData?.items || []);
  }, [columnData]);
  const items = state.map((item, index) => (
    <Draggable key={item._id} index={index} draggableId={item._id}>
      {(provided, snapshot) => (
        <div
          className={cx(classes.item, {
            [classes.itemDragging]: snapshot.isDragging,
          })}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <CustomCard
            src={config.CDN_URL + item.image}
            key={`${item?._id}`}
            handleDelete={() => {
              setDeleteModalVisible(true);
              setId(item?._id);
            }}
          />
        </div>
      )}
    </Draggable>
  ));

  const handleDelete = async (id) => {
    const tempColumnCards = columnData.items
      .filter((item) => item?._id !== id)
      .map((item, index) => {
        return {
          id: item?.id,
          order: index,
        };
      });
    const resolve = await services.updateSlides({
      items: tempColumnCards,
      type: "COLUMN_CARD",
    });
    if (resolve) {
      toast.success("Başarıyla Silindi");
      setDeleteModalVisible(false);
      columnDataRefetch();
    }
  };
  const handleAddColumn = async (data) => {
    if (data) {
      columnDataRefetch();
      setAddColumModalVisible(false);
    }
  };

  const updateSlideList = async (newOrder) => {
    try {
      let newColumnData = {
        items: newOrder.map((item, index) => ({
          id: item?.id,
          order: index,
        })),
        type: "COLUMN_CARD",
      };
      await services.updateSlides(newColumnData);
      toast.success("Güncellendi", {
        duration: 3000,
      });
    } catch (e) {
      toast.error("Değiştirilemiyor", {
        duration: 3000,
      });
    }
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmModal
        isOpen={deleteModalVisible}
        onConfirm={() => handleDelete(id)}
        onClose={() => {
          setDeleteModalVisible(false);
          setId(null);
        }}
        title={"Kartı silmek istediğinize emin misiniz"}
        confirmText={"Kartı Sil"}
      />
      <ColumnCreateModal
        isOpen={addColumModalVisible}
        onSend={handleAddColumn}
        oldColumnData={columnData?.items}
        onClose={() => setAddColumModalVisible(false)}
        title={"Kolon Ekle"}
      />
      <div className="sm:flex sm:items-center mb-10">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 dark:text-white text-slate-700">
            Sütun Sponsor Ayarları
          </h1>
          <p className="mt-2 text-sm dark:text-gray-400 text-gray-500">
            Sütunlardaki sponsorlar ile ilgili düzenlemelerinizi,
            sıralamalarınızı, gözükmesini istemediğiniz slaytların silmesini ve
            ekleme işlemini bu sayfada yapabilirsiniz.
          </p>
        </div>
      </div>
      {!isColumnCardLoading && (
        <DragDropContext
          onDragEnd={({ destination, source }) => {
            if (!destination) {
              return;
            }

            const newOrder = [...state];
            const [removed] = newOrder.splice(source.index, 1);
            newOrder.splice(destination.index, 0, removed);

            handlers.setState(newOrder);

            updateSlideList(newOrder);
          }}
        >
          <Droppable droppableId="dnd-list" direction="horizontal">
            {(provided) => (
              <div
                className="flex-wrap flex-row flex gap-4"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {items}
                {provided.placeholder}
                {state.length < 2 && (
                  <button
                    onClick={() => setAddColumModalVisible(true)}
                    type="button"
                    className="relative flex justify-center items-center min-w-[150px] rounded-lg border-2 border-dashed border-gray-300 p-12 m-3 mb-5 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <IconPlus width={25} height={25} />
                  </button>
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
};

export default ColumnList;
